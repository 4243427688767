<template>
    <div :id="id" :class="['plateBlock', customClass]">
        <ul>
            <li class="plate_item" v-for="(item, index) in data" :key="index">
                <div :class="item.class">
                    <template v-if="!item.fundNumTxt">
                        <div v-html="item.sSecNameTxt" />
                        <div class="num cut">{{ item.ariseTxt }}</div>
                        <div>{{ item.sHeadName }}</div>
                        <div class="num">{{ item.headAriseTxt }}</div>
                    </template>
                    <template v-if="item.fundNumTxt">
                        <div v-html="item.sSecNameTxt" />
                        <div class="num cut" v-html="item.fundNumTxt" />
                        <div>涨跌幅</div>
                        <div class="num">{{ item.ariseTxt }}</div>
                    </template>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { anyElement } from "@/components/anyElement.js";
export default {
    props: [ "id","customClass"],
    data: function() {
        return {
            data: []
        }
    },
    methods: {
        async PlateBlock(data, {
            width = 520,
            height = 340,
        }) {
            this.data = data.slice(0, 12)
            document.getElementById(this.id).style.cssText = `width: ${width}px;height: ${height}px;`
            this.$nextTick(() => {
                this.ffRow = anyElement({
                    targets: document.querySelectorAll(".plate_item"),
                    isSplitTxt: false,
                    duration: 1100,
                    delay: 160,
                    easing: "easeOutExpo",
                    inEffect: "topIn",
                    outEffect: "leftOutBig",
                });
                this.ffRow.runIn();
            })
        }
    }
}
</script>
<style lang="less" scoped>
.plateBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
        display: flex;
        flex-wrap: wrap;
        li {
            width: 25%;
            > div {
                margin: 3px;
                color: #fff;
                padding: 18px 0;
                text-align: center;
                line-height: 1.1;
                font-size: 22px;
                vertical-align: baseline;
                .num {
                    font-size: 26px;
                    margin-top: 8px;
                    font-weight: bold;
                }
                .cut {
                    margin-bottom: 24px;
                }
            }
            .rise_lev4 {
                background-color: #e64b4b;
            }
            .rise_lev3 {
                background-color: #ff7676;
            }
            .rise_lev2 {
                background-color: #ffcaca;
                color: #e64b4b;
            }
            .rise_lev1 {
                background-color: #ffebeb;
                color: #e64b4b;
            }
            .fall_lev4 { background-color: #199d3e; }
            .fall_lev3 { background-color: #50c672; }
            .fall_lev2 { background-color: #bfeecd; color: #4eb76c; }
            .fall_lev1 { background-color: #e4f4e8; color: #4eb76c; }
        }
    }
}
</style>
