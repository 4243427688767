<template>
  <div id="plateAreaChart">
    <div class="pages hidden" id="listPages">
      <div class="page_title"><span class="text hidden" id="listText"></span></div>
      <div class="page_date">{{ updateDate }}</div>
      <div ref="refPlateBlockHolder" v-if="isPlateBlockVisible">
        <plate-block ref="refPlateBlock" id="plateBlock" customClass="plate_block" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import plateBlock from "./plateBlock.vue"
export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: "",  // 数据更新日期
      isPlateBlockVisible: false,
    };
  },
  components: {
    plateBlock
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#listText").addClass("hidden");
        this.isPlateBlockVisible = false
        this.updateDate = "";
        resolve();
      });
    },
    async show(commandData) {
      this.isPlateBlockVisible = false
      //设置标题
      const title = commandData.data.pageTitle;
      $("#listPages").removeClass("hidden");
      $("#listText").html(title);

      this.$nextTick(async () => {
        $("#listText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#listText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        this.handlePlateBlock(commandData)
      });
    },
    handlePlateBlock(commandData) {
      this.isPlateBlockVisible = true;
      this.$nextTick(() => {
        this.renderPlateBlock(commandData);
      });
    },
    renderPlateBlock(commandData) {
      let width = this.$refs.refPlateBlockHolder.clientWidth;
      this.$refs.refPlateBlock.PlateBlock(commandData.data.data, {
        width: width,
        height: width * 0.77,
      });
      this.updateDate = commandData.data.date;
    },
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
